"use strict";


/**
 * Events module
 * @param {Object} appServer
 * @constructor
 */
var Events = function (appServer) {
    this.appServer = appServer;
    this._storage = {};
};
/**
 * Register new event
 * @param {String} e string "type:name:event"
 * @param {Function} fn function
 */
Events.prototype.on = function (e, fn) {
    if (!e) {
        console.error("You not pass event");
        return;
    }
    if (!fn) {
        console.error("You not pass function")
    }
    var event = this._normalizeEvent(e);

    if (!this._storage.hasOwnProperty(event.event)) {
        this._storage[event.event] = [];
    }
    this._storage[event.event].push({
        fn: fn, name: event.name, type: event.type
    });


};
/**
 * Convert string string "type:name:event"  to object
 * @param e
 * @private
 */
Events.prototype._normalizeEvent = function (e) {
    var eventArray = e.split(":");
    var retObj = {};
    retObj.event = eventArray.pop();
    retObj.name = (eventArray.length !== 0) ? eventArray.pop() : null;
    retObj.type = (eventArray.length !== 0) ? eventArray.pop() : null;
    return retObj;
};
/**
 * Remove event TODO
 * @param event
 * @param fn
 */
Events.prototype.off = function (event, fn) {
//TODO
};

/**
 * Trigger  event
 * @param {String} e string "type:name:event"
 * @param {Object} data transferred data
 */
Events.prototype.trigger = function (e, data) {
    //console.log("trigger",e, data,this);

    var event = this._normalizeEvent(e);

    var eventArr = this._storage[event.event];
    if (!eventArr) {
        return;
    }

    eventArr.forEach(function (val) {
        if (((!val.name) || (val.name === event.name)) && ((!val.type) || (val.type === event.type))) {

            val.fn(data);
        }
    });
};
/**
 * Get event count
 * @param e
 */
Events.prototype.count = function(e){
    var event = this._normalizeEvent(e);
    var eventArr = this._storage[event.event];
    if (!eventArr) {
        return 0;
    }
    return eventArr.length;
};

module.exports = Events;