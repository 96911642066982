"use strict";


/**
 * Shared Models Storage.
 * @constructor
 */
var SharedModelsStorage = function (appServer) {
    this.appServer = appServer;
    this._storage = {};
};
/**
 * Register SharedModelConstructor and store it
 * @param {Function} SharedModelConstructor instance constructor
 */
SharedModelsStorage.prototype._register = function (SharedModelConstructor) {
    this._storage[SharedModelConstructor.prototype.metaInfo.name] = new SharedModelConstructor(this.appServer,this.appServer.getProviderConfig(SharedModelConstructor.prototype.metaInfo.name));
};
/**
 * Return some constructor by name and optionally by version.
 * @param {Function} InstanceConstructor instance constructor
 * @param {Number|Object} version version of constructor to get or constructor
 */
SharedModelsStorage.prototype.getInstance = function (InstanceConstructor) {
    if (!InstanceConstructor.prototype.metaInfo.name) {
        console.error("Model should have metaInfo!");
        return false;
    }


    if (!this._storage.hasOwnProperty(InstanceConstructor.prototype.metaInfo.name)) {//if we have this storage
        this._register(InstanceConstructor);
    }
    return this._storage[InstanceConstructor.prototype.metaInfo.name];
};

/**
 * Remove model from storage
 * @param {String} name name of someConstructor
 * @param {Number} version version of constructor to remove
 */
SharedModelsStorage.prototype.unRegister = function (name, version) {
    //TODO
};


module.exports = SharedModelsStorage;