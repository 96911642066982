"use strict";
/**
 * Drag module. Provide su Drag and Drop in appServer
 * @param {Object} appServer
 * @constructor
 */
var Drag = function (appServer) {
    var that = this;
    this.appServer = appServer;
    this._storage = {
        data: null,
        delta: null,
        dom: null,
        dragContainers:[],
        type:null,
        currentContainer:null,
        objScrollZones: {},
        numScrollZones: 0,
        bolScrolling: false,
        timScrollCheck: null
    };
    this.pointer = {
        pageX: 0,
        pageY: 0,
        clientX: 0,
        clientY: 0,
        isDown:false
    };
    this.appServer.readOnlyVariables.pointer = this.pointer;
    this.transformStyle = this.getTransformStyle();
    this.appServer.getLibrary('jquery').then( function (jQuery) {
        that.$ = jQuery;
        that.init();
    })
    
};

/**
 * Init method. Attaching events and do other init stuff;
 */
Drag.prototype.init = function () {
	var bolChromePC = false;//TODO Why this variable??
	var bolChromeBook = false;
    var that = this;
    // Start base drag listeners
    this.appServer.on("dragstart", this.onDragStart.bind(this));
    this.appServer.on("dragUpdateContainers", this.updateContainersPosition.bind(this));
    this.appServer.tools.getPointsEvents();
    // Detect if on PC and using Chrome browser
    if (window.navigator.platform.indexOf("Win") > -1) {
    	if (window.chrome) {
    		bolChromePC = true;
    	}
    }
    if (window.navigator.userAgent.indexOf("CrOS") > -1) {
        bolChromeBook = true;
    }

    window.addEventListener("scroll", this.updateContainersPosition.bind(this), false);//for fixed position we should update containers
	window.addEventListener("resize", this.resizeAutoScrollRegions.bind(this), false);
    // Set the dual events to support chrome on PC
    if (bolChromePC == true || bolChromeBook == true) {//TODO Why this copyPaste of original code?
		// KAL 090214 Set drag starts for dual events
	    document.addEventListener("touchstart", function (e) {
	        that.updatePointerPosition(e);
	        that.pointer.isDown = true;
	        if(bolChromeBook === true) {
                e.stopPropagation();
                e.preventDefault();
			}
	    }, false);
	    document.addEventListener("mousedown", function (e) {
	        that.updatePointerPosition(e);
	        that.pointer.isDown = true;
	    }, false);
	    document.addEventListener("dragstart", function (e) {//for IE.
	        if (that._storage.dom){//if we dragging some
	            e.stopPropagation();
	            //e.preventDefault();
	        }
	    }, false);

		// KAL 090214 Set cancel drag for dual events
	    document.addEventListener("touchcancel", function (e) {
	        that.updatePointerPosition(e);
	        that.pointer.isDown = false;
            if(bolChromeBook === true) {
                e.preventDefault();
            }
	    }, false);
	    document.addEventListener("mouseout", function (e) {
	        that.updatePointerPosition(e);
	        that.pointer.isDown = false;
	    }, false);
	
		// KAL 090214 Set dragging for dual events
	    document.addEventListener("touchmove", function (e) {
	        that.updatePointerPosition(e);
	        if (that._storage.dom){
	            if (that.appServer.tools.getPointsEvents().isTouch){//Android fix  http://stackoverflow.com/questions/10367854/html5-android-touchcancel TODO fix only for android
	                //e.preventDefault();
	            }
	            that.onDragging(e);
                if(bolChromeBook === true) {
                    e.stopPropagation();
                    e.preventDefault();
                }
	        }
	    }, false);
	    document.addEventListener("mousemove", function (e) {
	        that.updatePointerPosition(e);
	        if (that._storage.dom){
	            if (that.appServer.tools.getPointsEvents().isTouch){//Android fix  http://stackoverflow.com/questions/10367854/html5-android-touchcancel TODO fix only for android
	                //e.preventDefault();
	            }
	            that.onDragging(e);
	        }
	    }, false);
	
		// KAL 090214 Set drag drop for dual events
	    document.addEventListener("touchend", function (e) {
	        that.pointer.isDown = false;
	        that.updatePointerPosition(e);
	        if (that._storage.dom){
	            that.onDragEnd();
	        }
	    }, false);
	    document.addEventListener("mouseup", function (e) {
	        that.pointer.isDown = false;
	        that.updatePointerPosition(e);
	        if (that._storage.dom){
	            that.onDragEnd();
	        }
	    }, false);
	} else {
		// Non-Chrome Browser PC
		document.addEventListener(this.appServer.tools.getPointsEvents().start, function (e) {
	        that.updatePointerPosition(e);
	        that.pointer.isDown = true;
	    }, false);
	
	    document.addEventListener("dragstart", function (e) {//for IE.
	        if (that._storage.dom){//if we dragging some
	            e.stopPropagation();
	            //e.preventDefault();
	        }
	    }, false);
	    
	    document.addEventListener(this.appServer.tools.getPointsEvents().cancel, function (e) {
	        that.updatePointerPosition(e);
	        that.pointer.isDown = false;
	    }, false);
	
	    document.addEventListener(this.appServer.tools.getPointsEvents().move, function (e) {
	        that.updatePointerPosition(e);
	        if (that._storage.dom){
	        	if (navigator.userAgent.indexOf("MSIE") != -1 || /rv:11.0/i.test(navigator.userAgent)) {
	        		e.preventDefault();
	        		//e.stopPropagation();
	    		}
	            if (that.appServer.tools.getPointsEvents().isTouch){//Android fix  http://stackoverflow.com/questions/10367854/html5-android-touchcancel TODO fix only for android
	                e.preventDefault();
	            }
	            that.onDragging(e);
	        }
	    }, false);
	    
	    document.addEventListener(this.appServer.tools.getPointsEvents().end, function (e) {
	        that.pointer.isDown = false;
	        that.updatePointerPosition(e);
	        if (that._storage.dom){
	            that.onDragEnd();
	        }
	    }, false);
	}
};
/**
 * Updating cache with current pointer position
 * @param {event} e mouse or touch event
 */
Drag.prototype.updatePointerPosition = function(e){
    if (e.touches && e.touches.length > 0) {
        this.pointer.pageX = e.touches[0].pageX;
        this.pointer.pageY = e.touches[0].pageY;
        this.pointer.clientX = e.touches[0].clientX;
        this.pointer.clientY = e.touches[0].clientY;
    } else {
        this.pointer.pageX = e.pageX;
        this.pointer.pageY = e.pageY;
        this.pointer.clientX = e.clientX;
        this.pointer.clientY = e.clientY;
    }
};

/**
 * Whe drag is start we will process it
 * @param {Object} e event in special format. See example
 * @example
 * Event format:
 * {
 *  dom: DOM,//dom node OR - not required
 *  img: "imageURL",  //image OR - not required
 *  text:"someText", //text - not required
 *  delta:{//delta of pointer (mouse) cursor
 *      top:5,
 *      left:5
 *  },
 *   data:{ // data in your(!) format
 *
 *  },
 * type: "test" //type of drag object for filtration
 */
Drag.prototype.onDragStart = function (e) {
    if (this._storage.dom) return; //check if already move some dom
	this._storage.dom = document.createElement("div");
	
    if (e.dom) {
        this._storage.dom.appendChild(e.dom);
    } else if (e.img) {

    } else if (e.text) {

    } else {
        console.error("You should provide dom,img or text");
        this._storage.dom = null;
        //TODO Document.elementFromPoint
    }

    this._storage.delta = e.delta;
    this._storage.data = e.data;
    this._storage.type = e.type;
	this._storage.source = e.source;
	
    this._storage.dragContainers = this._findContainers();
    for (var ind=0;ind<this._storage.dragContainers.length;ind++){
        this._storage.dragContainers[ind][1].classList.add("app-server-drag-started");
    }
    this._storage.dom.classList.add("app-server-dragging");
    this._storage.source.classList.add('app-server-dragging-source');
    document.body.appendChild(this._storage.dom);
	document.body.classList.add("disable-select");
};

/**
 * Find container for dragging
 * @private
 * @return {Array} containers
 */
Drag.prototype._findContainers = function(){
    //console.time("Iterate containers");
    var containers= [];
    var dragContainers = document.querySelectorAll("[data-drag-filter]");
    for (var ind=0;ind<dragContainers.length;ind++){
        if (dragContainers[ind].getAttribute("data-drag-filter").split(",").indexOf(this._storage.type) !==-1){
            var coords= this.appServer.tools.getOffsetDocument(dragContainers[ind]);
            coords.width= dragContainers[ind].offsetWidth;
            coords.height= dragContainers[ind].offsetHeight;
            containers.push([coords,dragContainers[ind]]);
        }
    }
    //console.timeEnd("Iterate containers");
    return containers;

};
/**
 * Updates drag container
 */
Drag.prototype.updateContainersPosition = function(){
    if (!this._storage.dom){//update only we need to update
        return;
    }
    var newContainers = this._findContainers();
    for (var ind=0;ind<this._storage.dragContainers.length;ind++){
        this._storage.dragContainers[ind][1].classList.remove("app-server-drag-started");
    }

    this._storage.dragContainers = newContainers;
    for (ind=0;ind<this._storage.dragContainers.length;ind++){
        this._storage.dragContainers[ind][1].classList.add("app-server-drag-started");
    }
};
/**
 * Updates auto scroll regions
 */
Drag.prototype.resizeAutoScrollRegions = function () {
	var strScrollAreaId = "";
	var objScrollArea;
	var numWidth = document.getElementsByTagName("html")[0].offsetWidth;
	var numHeight = document.getElementsByTagName("html")[0].offsetHeight;
	if (this._storage.numScrollZones > 0) {
		for (strScrollAreaId in this._storage.objScrollZones) {
			if (this._storage.objScrollZones.hasOwnProperty(strScrollAreaId)) {
				objScrollArea = this._storage.objScrollZones[strScrollAreaId];
				if (objScrollArea.strType == "region") {
					switch(objScrollArea.dynArea) {
						case "left":
							objScrollArea.numBottom = numHeight;
							break;
						case "top":
							objScrollArea.numRight = numWidth;
							break;
						case "right":
							objScrollArea.numRight = numWidth;
							objScrollArea.numLeft = numWidth - 50;
							objScrollArea.numBottom = numHeight;
							break;
						case "bottom":
							objScrollArea.numTop = numHeight - 50;
							objScrollArea.numBottom = numHeight;
							objScrollArea.numRight = numWidth;
							break;
					}	
				}
			}
		}
	}
};
/**
 * Timed check for scroll while dragging
 */
Drag.prototype.onScrolling = function () {
	var that = this;
	var objDelta = this.scrollCheck();
	var bolChanged = false;
	var top = 0;
    var left = 0;
	// Modify position of dragged elements
	if (objDelta.x != 0) {
		this.pointer.pageX += objDelta.x;
	}
	if (objDelta.y != 0) {
		this.pointer.pageY += objDelta.y;
	}
	bolChanged = objDelta.bolMoved;
	// Change the location of the dragged elements
	if (bolChanged == true) {
		top = this.pointer.pageY - this._storage.delta.top;
    	left = this.pointer.pageX - this._storage.delta.left;
		if (this.transformStyle === "msTransform"){// IE9 not supported translate3d but we should use it to enable hardware acceleration.
			this._storage.dom.style[this.transformStyle] = "translate(" + left + "px," + top + "px)";
		} else {
			this._storage.dom.style[this.transformStyle] = "translate3d(" + left + "px," + top + "px,0)";
		}
	}
	// Set next scroll check
	this._storage.timScrollCheck = setTimeout(function () { that.onScrolling() }, 100);
};
/**
 * Checks for autoscroll regions and operates scroll through callback functions
 * @returns {Object} .x Delta X   .y Delta Y
 */
Drag.prototype.scrollCheck = function () {
	var that = this;
	var strScrollAreaId = "";
	var objScrollArea;
	var numXscrollLoc = this.pointer.clientX;
	var numYscrollLoc = this.pointer.clientY;
	var objDelta = {"x": 0, "y": 0, "bolMoved": false};
	var objSingleDelta;
	// Check against auto-scroll areas
	if (this._storage.numScrollZones > 0) {
		for (strScrollAreaId in this._storage.objScrollZones) {
			if (this._storage.objScrollZones.hasOwnProperty(strScrollAreaId)) {
				objScrollArea = this._storage.objScrollZones[strScrollAreaId];
				if (objScrollArea.bolActive) {
					if (numYscrollLoc >= objScrollArea.numTop && numYscrollLoc <= objScrollArea.numBottom) {
						if (numXscrollLoc >= objScrollArea.numLeft && numXscrollLoc <= objScrollArea.numRight) {
							objScrollArea.appBase = this;
							objSingleDelta = objScrollArea.cbfScrollActivator();
							objDelta.bolMoved = true;
							if (objScrollArea.strScrollType != "div") {
								objDelta.x += objSingleDelta.x;
								objDelta.y += objSingleDelta.y;
							}
						}						
					}
				}
			}
		}
	}
	return objDelta;
};
/**
 * This method perform moving of object and calculating iteration with other objects
 */
Drag.prototype.onDragging = function(evt){
	var that = this;
	var top = this.pointer.pageY - this._storage.delta.top;
    var left = this.pointer.pageX - this._storage.delta.left;
    var objScrollDelta;
    if (this._storage.bolScrolling == false) {
    	objScrollDelta = this.scrollCheck();
    	if (objScrollDelta.bolMoved) {
			this._storage.bolScrolling = true;
			this._storage.timScrollCheck = setTimeout(function () { that.onScrolling() }, 100);
		}
	}
    if (this.transformStyle === "msTransform"){// IE9 not supported translate3d but we should use it to enable hardware acceleration.
        this._storage.dom.style[this.transformStyle] = "translate("+left+"px,"+top+"px)";
    } else {
        this._storage.dom.style[this.transformStyle] = "translate3d("+left+"px,"+top+"px,0)";
    }

    var matchIndex;
    var event;

    if (this._storage.currentContainer){//we already have a container
        matchIndex = this.detectCollisions([this._storage.currentContainer],this.pointer);
        if (matchIndex === false){// and we left container
            this._storage.currentContainer[1].classList.remove("drag-over");
            event = new CustomEvent("appServerDragLeave", {"detail":{data:this._storage.data,type:this._storage.type,appServer:this.appServer}});
            this._storage.currentContainer[1].dispatchEvent(event);
            this._storage.currentContainer = null;
        } else { //if we have the same container - return - nothing to do
            return;
        }
    }

    matchIndex = this.detectCollisions(this._storage.dragContainers,this.pointer);

    if (matchIndex !==false){
        this._storage.currentContainer = this._storage.dragContainers[matchIndex];
        this._storage.currentContainer[1].classList.add("drag-over");
        event = new CustomEvent("appServerDragEnter", {"detail":{data:this._storage.data,type:this._storage.type,appServer:this.appServer},"bubbles":true});
        this._storage.currentContainer[1].dispatchEvent(event);
        //console.log("drag over");
    }


};

/**
 * When drag is end
 */
Drag.prototype.onDragEnd =function (){
	if (this._storage.bolScrolling == true) {
		this._storage.bolScrolling = false;
		clearTimeout(this._storage.timScrollCheck);
	}
    this._storage.dom.parentNode.removeChild(this._storage.dom);
    document.body.classList.remove("disable-select");
    var eventDragEnd = new CustomEvent("appServerDragEnd", {"detail":{data:this._storage.data,type:this._storage.type,appServer:this.appServer, source: this._storage.source},"bubbles":true});
	this._storage.source.dispatchEvent(eventDragEnd);
    if (this._storage.currentContainer){//we already have a container
            this._storage.currentContainer[1].classList.remove("drag-over");
            var event = new CustomEvent("appServerDrop", {"detail":{data:this._storage.data,type:this._storage.type,appServer:this.appServer},"bubbles":true});
			this._storage.currentContainer[1].dispatchEvent(event);
            this._storage.currentContainer = null;
    }
    this._storage.dom=null;
    for (var ind=0;ind<this._storage.dragContainers.length;ind++){
        this._storage.dragContainers[ind][1].classList.remove("app-server-drag-started");
    }

    this._storage.source.classList.remove('app-server-dragging-source');
    this._storage.source = null;
    this._storage.dragContainers=[];
};


/**
 * Detect container under cursor
 * @param {Array} arr array of container
 * @param {Object} pointer
 * @param {Object} pointer.pageX
 * @param {Object} pointer.pageY
 * @param {Object} pointer.clientX
 * @param {Object} pointer.clientY
 * @returns {boolean|Number}
 */
Drag.prototype.detectCollisions = function(arr, pointer) {
	var ind = false;
    var left = pointer.pageX;
    var top = pointer.pageY;
    var numScrollAdjust = 0;
    var numParentLevel = 0;
    var eleScrollDiv;
    for (var i = 0, l = arr.length; i < l; i++) {
    	// Check for if target container is marked as in a scrolling div
    	if (arr[i][1].attributes["data-drag-scroll"]) {
	    	numParentLevel = Number(arr[i][1].attributes["data-drag-scroll"].value);
	    	eleScrollDiv = arr[i][1];
	    	while (numParentLevel > 0) {
	    		eleScrollDiv = eleScrollDiv.parentElement;
	    		numParentLevel = numParentLevel - 1;
	    	}
	    	if (eleScrollDiv.scrollTop > 0) {
	        	numScrollAdjust = -eleScrollDiv.scrollTop;
	        }
	    }
	    if ((left > arr[i][0].left)
	     		&& (left < (arr[i][0].left + arr[i][0].width))
	       		&& (top > arr[i][0].top + numScrollAdjust)
	       		&& (top < (arr[i][0].top + arr[i][0].height + numScrollAdjust))) {
	        ind=i;
	        break;
	    }
    }
    return ind;
};



/**
 * Try to detect transform style. Supports: "transform","webkitTransform" and "msTransform" (IE9);
 * @returns {String|Boolean} transform style or false
 */
Drag.prototype.getTransformStyle = function () {
    var el = document.createElement("div");
    if (el.style.transform !== void 0) {
        return "transform";
    }

    if (el.style.webkitTransform !== void 0) {
        return "webkitTransform";
    }
    if (el.style.msTransform !== void 0) {
        return "msTransform";
    }
    console.error("Transform is not supported! You can't use Drag module");
    return false;
};

/**
 * Adds a drag and drop auto-scroll area to the page
 * @param {String} p_strId The id which the auto-scroll can later be disposed by
 * @param {Dynamic} p_dynArea Either a string with a known region (top, bottom, left, or right), or an object rect {top, bottom, left, right}, or an element
 * @param {String} p_cbfScrollActivator
 * @param {String} p_strScrollType  Either "div" or "doc". Default is "doc"
 */
Drag.prototype.addDragScroll = function (p_strId, p_dynArea, p_cbfScrollActivator, p_strScrollType) {
	var objPosition = {};
	var objDragScroll = {};
	var bolValidData = true;
	var strError = "";
    var $ = this.$;
	var strAreaType = $.type(p_dynArea);
	// Check for valid p_strId
	if (p_strId == "") {
		bolValidData = false;
		strError = "p_strId is required";
	} else {
		if (this._storage.objScrollZones[p_strId]) {
			bolValidData = false;
			strError = "p_strId(" + p_strId + ") already exists";
		}
	}
	// Check for valid p_dynArea
	if (strAreaType == "object") {
		if (p_dynArea.hasOwnProperty("left")) {
			strAreaType = "rect";
		} else {
			strAreaType = "invalid";
			if ($) {
				if ($(p_dynArea).length > 0) {
					strAreaType = "element";
				}
			}
			
		}
	} else {
		if (strAreaType == "string") {
			switch (p_dynArea.toLowerCase()) {
				case "left":
				case "right":
				case "top":
				case "bottom":
					p_dynArea = p_dynArea.toLowerCase();
					strAreaType = "region";
					break;
				default:
					strAreaType = "invalid";
					break;
			}
		} else {
			strAreaType = "invalid";
		}
	}
	if (strAreaType == "invalid") {
		bolValidData = false;
		strError = "p_dynArea is required to be a element, rect object, or element";
	}
	// Check if p_cbfScrollActivator is a function
	if ($.type(p_cbfScrollActivator) !== "function") {
		bolValidData = false;
		strError = "p_cbfScrollActivator is required to be a function";
	}
	// Display error message
	if (bolValidData == false) {
		console.log("<<<<<<<<<<<<<<< Drag.js: addDragScroll >>>>>>>>>>>>>>");
		console.log(strError);
		console.log("<<<<<<<<<<<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>>>>>>>>>>>");
	} else {
		// 
		objDragScroll.strScrollType = "doc";
		if (p_strScrollType) {
			objDragScroll.strScrollType = "div";
		}
		// Create new drag scroll area object
		objDragScroll.strId = p_strId;
		objDragScroll.strType = strAreaType;
		objDragScroll.dynArea = p_dynArea;
		objDragScroll.cbfScrollActivator = p_cbfScrollActivator;
		objDragScroll.bolActive = true;
		objDragScroll.bolScrolling = false;
		switch (strAreaType) {
			case "element":
				objPosition = $(p_dynArea).position();
				objDragScroll.numLeft = objPosition.left;
				objDragScroll.numTop = objPosition.top;
				objDragScroll.numRight = objDragScroll.numLeft + ($(p_dynArea).width() - 1);
				objDragScroll.numBottom = objDragScroll.numTop + ($(p_dynArea).height() - 1);
				break;
			case "rect":
				objDragScroll.numLeft = p_dynArea.left;
				objDragScroll.numTop = p_dynArea.top;
				objDragScroll.numRight = objDragScroll.numLeft;
				objDragScroll.numBottom = objDragScroll.numTop;
				if (p_dynArea.hasOwnProperty("right")) {
					objDragScroll.numRight = p_dynArea.right;
				} else {
					if (p_dynArea.hasOwnProperty("width")) {
						objDragScroll.numRight = objDragScroll.numRight + p_dynArea.width;
					}
				}
				if (p_dynArea.hasOwnProperty("bottom")) {
					objDragScroll.numBottom = p_dynArea.bottom;
				} else {
					if (p_dynArea.hasOwnProperty("height")) {
						objDragScroll.numBottom = objDragScroll.numBottom + p_dynArea.height;
					}
				}
				break;
			case "region":
				if (p_dynArea == "top") {
					objDragScroll.numLeft = 0;
					objDragScroll.numTop = 0;
					objDragScroll.numRight = window.innerWidth;
					objDragScroll.numBottom = 50;
				}
				if (p_dynArea == "bottom") {
					objDragScroll.numLeft = 0;
					objDragScroll.numTop = window.innerHeight - 50;
					objDragScroll.numRight = window.innerWidth;
					objDragScroll.numBottom = window.innerHeight;
				}
				if (p_dynArea == "left") {
					objDragScroll.numLeft = 0;
					objDragScroll.numTop = 0;
					objDragScroll.numRight = 50;
					objDragScroll.numBottom = window.innerHeight;
				}
				if (p_dynArea == "right") {
					objDragScroll.numLeft = window.innerWidth - 50;
					objDragScroll.numTop = 0;
					objDragScroll.numRight = window.innerWidth;
					objDragScroll.numBottom = window.innerHeight;
				}
				break;	
		};
		this._storage.objScrollZones[p_strId] = objDragScroll;
        this._storage.numScrollZones = this._storage.numScrollZones + 1;
	}
};

/**
 * Set the active state of an auto-scroll 
 * @param {String} p_strId The id of the scroll area to change the active state of
 * @param {Boolean} p_bolActive Is the scroll area active?
 */
Drag.prototype.setDragScrollActive = function (p_strId, p_bolActive) {
	if (p_strId != "") {
		if (this._storage.objScrollZones.hasOwnProperty(p_strId)) {
			this._storage.objScrollZones[p_strId].bolActive = p_bolActive;
		}
	}
};

/**
 * Removes a drag and drop auto-scroll area to the page
 * @param {String} p_strId The id of the auto-scroll to be disposed of
 * @return {Boolean} Was a drag and drop auto-scroll deleted?
 */
Drag.prototype.removeDragScroll = function (p_strId) {
	var bolDeleted = false;
	if (p_strId != "") {
		if (this._storage.objScrollZones.hasOwnProperty(p_strId)) {
			delete this._storage.objScrollZones[p_strId];
			this._storage.numScrollZones = this._storage.numScrollZones - 1;
			bolDeleted = true;
		}
	}
	return bolDeleted;
};
module.exports = Drag;