var AppServer = require("./AppServer/AppServer");
var config = require("./AppServer/configController");


(function(){
    if (window[config.appServerName]){
        console.error("This name is already taken!");
        //debugger
    }
    window[config.appServerName]= new AppServer(window,"appServerContainer");
    //window[config.appServerName].config = config;

})();
