"use strict";


/**
 * Modules Storage.
 * @param {Object} appServer
 * @constructor
 */
var ModulesStorage = function (appServer) {
    this.appServer = appServer;
    this._storage = {};
};
/**
 * Getting module by module name
 * @param {String} url url of module
 * @returns {Promise}
 */
ModulesStorage.prototype.getModule = function (url) {
    var that = this;
    return new Promise(function (resolve, reject) {
        if (!that._storage.hasOwnProperty(url)) {
//            if (optionalConstructor){//TODO maybe in future we will create modules inside js
//                that._storage[url]= optionalConstructor;
//            } else {
            that._storage[url] = that._downloadModule(url);
//            }

        }
        resolve(that._storage[url]);

    });
};

/**
 * Internal function to download module
 * @param {String} url module to download
 * @returns {Promise}
 * @private
 */
ModulesStorage.prototype._downloadModule = function (url) {
    var that = this;
    var oldIE = false;
    // Return a new promise.
    return new Promise(function (resolve, reject) {       

        var xhr;// = new XMLHttpRequest();
        if ((typeof window !== 'undefined') && window.XDomainRequest && (window.XMLHttpRequest && new XMLHttpRequest().responseType === undefined) && (url.indexOf("http") === 0)) {//old IE CORS
            xhr = new XDomainRequest();
            oldIE = true;
            //http://social.msdn.microsoft.com/Forums/ie/en-US/30ef3add-767c-4436-b8a9-f1ca19b4812e/ie9-rtm-xdomainrequest-issued-requests-may-abort-if-all-event-handlers-not-specified?forum=iewebdevelopment
            xhr.onprogress = function () {
            };//IE FIX
        } else {
            xhr = new XMLHttpRequest()
        }
        url = (url.lastIndexOf(".js") + 3 === url.length) ? url : (url + "/index.js");
        xhr.open('get', url);
        xhr.onload = function (e) {//On loaded
            if (oldIE || xhr.status == 200) {//check the status
                if (oldIE) {
                    this.response = this.responseText;
                }
                var answer = (this.response[this.response.length - 1] === ";") ? this.response.substring(0, this.response.length - 1) : this.response;
                var response;                
                try {
                    response = new Function("var appServerBenchmark=this; return (" + answer + "\n);");//create closure to transfer AppServer variable.
                } catch (e) {
                    console.error(e);
                    reject(Error("Could not wrap module"));//reject with the status text
                    return;
                }
                //Note \n is mandatory, else if module ends with "//" comment it may fail
                //var ModuleFunction = response.call(that.appServer);//Call module (also add css to head if available);
                response.call(that.appServer).then(function (mod) {
                    try {
                        that._storage[url] = mod();
                    } catch (e) {
                        console.error(e);
                        console.error("url:", url);
                        //console.error(" processed responce:",answer);
                        //debugger
                    }
                    resolve(that._storage[url]);// Resolve the promise with the response text

                });//Call module (also add css to head if available);

            }
            else {
                reject(Error(xhr.statusText));//reject with the status text
            }
        };
        xhr.onerror = function () {// Handle network errors
            reject(Error("Network Error"));
        };
//        if (!oldIE){
//            xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
//            xhr.setRequestHeader('Accept', 'text/javascript, text/html, application/xml, text/xml, */*');
//        }


        xhr.send();        // Make the request
    });


};


module.exports = ModulesStorage;