"use strict";

/**
 * This is a collection of tools
 * @param {Object} appServer
 * @constructor
 */
var Tools = function (appServer) {
    this._cache = {};//Internal cache for some function
    this.appServer = appServer;
};

/**
 * Getting position relative to document
 * @param {Object} elm dom node
 * @returns {{top:number, left:number}}
 */
Tools.prototype.getOffsetDocument = function (elm) {
    var coordinates = {
        top: 0,
        left: 0
    };
    //var lastElm;
    if (elm.offsetParent) {
        do {
            coordinates.left += elm.offsetLeft;
            coordinates.top += elm.offsetTop;
            //lastElm = elm;
            if (getComputedStyle(elm).position === 'fixed'){//FF and IE fix
                coordinates.left+=(window.pageXOffset || document.documentElement.scrollLeft);
                coordinates.top+=(window.pageYOffset || document.documentElement.scrollTop);
                break;
            }
        } while (elm = elm.offsetParent);
//        if (getComputedStyle(lastElm).position === 'fixed'){
//            coordinates.left+=(window.pageXOffset || document.documentElement.scrollLeft);
//            coordinates.top+=(window.pageYOffset || document.documentElement.scrollTop);
//        }

    }


    return coordinates;
};

/**
 * Get events (touch or mouse) depends on touch support.
 * @returns {{start: string, move: string, end: string}}
 */
Tools.prototype.getPointsEvents = function () {
    if (!this._cache.events) {
        if ("ontouchstart" in document.documentElement) {
            this._cache.events = {
                start: "touchstart",
                move: "touchmove",
                end: "touchend",
                cancel:"touchcancel",
                isTouch: true
            }
        } else {
            this._cache.events = {
                start: "mousedown",
                move: "mousemove",
                end: "mouseup",
                cancel:"mouseout",
                isTouch: false
            }
        }
    }
    return this._cache.events;
};

/**
 * Get delta between pointer position and node
 * @param {Node} node dom node
 * @returns {{top: number, left: number}}
 */
Tools.prototype.getPointerOffset = function (node) {
    var coords = this.getOffsetDocument(node);
    return {
        top: this.appServer.readOnlyVariables.pointer.pageY - coords.top,
        left: this.appServer.readOnlyVariables.pointer.pageX - coords.left
    }
};

/**
 * Little helper for init dragging
 * @param {Node} node dom node
 * @param {String} type type of draggable object
 * @param {Object} data data to drag
 */
Tools.prototype.simpleDrag = function (node,type,data) {
    if (this.appServer.readOnlyVariables.pointer.isDown) {
        this.appServer.trigger("dragstart", {
            dom: node.cloneNode(true),
            source: node,
            delta: this.getPointerOffset(node),
            data: data,
            type:type
        });
    }
};
module.exports = Tools;

