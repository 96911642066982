// modules are defined as an array
// [ module function, map of requireuires ]
//
// map of requireuires is short require name -> numeric require
//
// anything defined in a previous bundle is accessed via the
// orig method which is the requireuire for previous bundles

//(function outer (modules, cache, entry) {

(function (modules, cache, entry) {
    "use strict";

    // Save the require from previous bundle to this closure if any
    //var previousRequire = typeof require == "function" && require;


    //function newRequire(name, jumped){
    function newRequire(name) {
        name = (name) ? name : entry[0]; //return
        if (!cache[name]) {
            if (!modules[name]) {
                // if we cannot find the the module within our internal map or
                // cache jump to the current global require ie. the last bundle
                // that was added to the page.
//                var currentRequire = typeof require == "function" && require;
//                if (!jumped && currentRequire) return currentRequire(name, true);

                // If there are other bundles on this page the require from the
                // previous one is saved to 'previousRequire'. Repeat this as
                // many times as there are bundles until the module is found or
                // we exhaust the require chain.
//                if (previousRequire) return previousRequire(name, true);
                throw new Error('Cannot find module \'' + name + '\'');
            }
            var m = cache[name] = {exports: {}};
            modules[name][0].call(m.exports,//this
                function (x) {// require
                    var id = modules[name][1][x];
                    return newRequire(id ? id : x);
                }
                , m //module
                , m.exports //module.exports
                //,outer //???
                //,modules //???
                //,cache //???
                //,entry //???
            );
        }

        return cache[name].exports;
    }
//    if (!window.firstPrelude){
//        newRequire(entry[0]);
//        window.firstPrelude =true;
//    }
    var i, mod, promArr = [], promInd = [];
    if (typeof appServerBenchmark !== "undefined") { //if runned from app Server
        for (i in modules) {
            if (typeof modules[i][0] === 'string'){//if not a function (like "COMMON_MODEL/demo")
                mod = modules[i][0].slice(0, modules[i][0].indexOf("/"));
                switch (mod) {
                    case 'COMMON_MODEL':
                        promArr.push(appServerBenchmark.getSharedModel(modules[i][0].slice(modules[i][0].indexOf("/") + 1)));
                        promInd.push(i);
                        break;
                    case 'SHARED_LIB':
                        promArr.push(appServerBenchmark.getLibrary(modules[i][0].slice(modules[i][0].indexOf("/") + 1)));
                        promInd.push(i);
                        break;
                    case 'STYLE':
                        appServerBenchmark.registerStyle(modules[i][0].slice(modules[i][0].indexOf("/") + 1));
                        cache[i] = {//hack to avoid die of prelude.
                            exports: null
                        };
                        break;
                    default:
                        console.error("Unknown type", mod);

                        break;
                }

            }

        }
    } else {
        for (i = 0; i < entry.length; i++) newRequire(entry[i]);
        return newRequire;
    }

    return new Promise(function (resolve, reject) {
        Promise.all(promArr).then(function (values) {
            for (var n = 0; n < values.length; n++) {
                if (!cache[promInd[n]]) {
                    cache[promInd[n]] = {
                        exports: null
                    };
                }
                cache[promInd[n]].exports = values[n];
            }
            resolve(newRequire);


            // values == [ true, 3 ]
        });
    });


    // Override the current require with this new one
//    return newRequire;
})
