/**
 * IE not supported CustomEvent construstor. But should
 * Adapted form  https://developer.mozilla.org/en/docs/Web/API/CustomEvent
 */
(function () {
    function enableCustomEventConstructor(){
        function CustomEvent(event, params) {
            params = params || { bubbles: false, cancelable: false, detail: undefined };
            var evt = document.createEvent('CustomEvent');
            evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
            return evt;
        }
        CustomEvent.prototype = window.Event.prototype;
        window.CustomEvent = CustomEvent;
    }

    try {
        new CustomEvent("test")
    } catch (e){
        console.warn("CustomEvent not supported! Enabling polyfill");
        enableCustomEventConstructor();
    }



})();