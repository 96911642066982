"use strict";


var ModulesStorage = require('./modules/ModulesStorage');
var SharedModelsStorage = require('./modules/SharedModelsStorage');
var Events = require('./modules/Events');
var Tools = require('./modules/Tools');
var Drag = require('./modules/Drag');

var urls = require('./configController');
require('./css/normalize.css'); //append base styles
require('./css/main.css');//append base styles
require('./css/appServer.less');//append appServer styles
require('./polyfills/es6-promise');//append polyfills
require('./polyfills/classList');//append polyfills
require('./polyfills/console');//for OLD IE.
require('./polyfills/customEvent');//for IE.
require('./polyfills/url-search-params');//for IE.

/**
 * App Server.
 * @param {Object} parent parent object. Window is root AppServer
 * @param container
 * @constructor AppServer
 */
var AppServer = function (parent, container) {
    this.container = this._getDOMNode(container);
    this._applicationStorage = {};
    this._appServersChildrenStorage = [];//we should have links to children AppServers
    this._registeredStyles = [];
    this._events = new Events(this);
    this.parentAppServer = this._detectIfRootAppServer(parent);


};
/**
 * Detect  RootAppServer
 * @param {Object} parent of AppServer (may be window or another AppServer)
 * @private
 */
AppServer.prototype._detectIfRootAppServer = function (parent) {
    if (parent !== window) {//if not a top level AppServer
        return  parent;
    }
    this._modulesStorage = new ModulesStorage(this); //init Modules Storage
    //this.sharedModelsStorage = new SharedModelsStorage(this);//init Model Storage
    this.readOnlyVariables = {};
    this.tools = new Tools(this);//we need to access to tools from other objects

    this._drag = new Drag(this);
    return false;
};

/**
 * Get module
 * @param {String} type type of module (different path on server)
 * @param {String} path path on the server
 * @private
 */
AppServer.prototype._getModule = function (type, path) {
   // console.log("Get module", type, path);
    if (this.parentAppServer) {
        return this.parentAppServer._getModule(type, path);
    }
    return this._modulesStorage.getModule(urls[type] + path);

};

/**
 * Start Embedded application
 * @param {String} EmbeddedApplicationName embedded application name  to start
 * @param {Node} container dome node of container
 * @param {Object} [options] option to pass in embedded application
 */
AppServer.prototype.startEmbeddedApplication = function (EmbeddedApplicationName, container, options) {
    container = this._getDOMNode(container);
    var that = this;
    return new Promise(function (resolve, reject) {
        that._getModule("application", EmbeddedApplicationName).then(function (EmbeddedApplication) {//getting application
            if (!that._applicationStorage[EmbeddedApplicationName]) {
                that._applicationStorage[EmbeddedApplicationName] = [];
            }
            that._applicationStorage[EmbeddedApplicationName].push(new EmbeddedApplication(container, that, options));//starting application
            resolve(that._applicationStorage[EmbeddedApplicationName][that._applicationStorage[EmbeddedApplicationName].length - 1]);
        },reject);
    });
};

/**
 *  Primary for preload reason
 * @param {String} EmbeddedApplicationName name of application to load
 * @return {Promise}
 */
AppServer.prototype.loadEmbeddedApplication = function(EmbeddedApplicationName){
    return this._getModule("application", EmbeddedApplicationName)
};

/**
 * Stop application and remove it from memory
 * @param {Object}appInstance
 * @param {String} name name off eApp
 */
AppServer.prototype.stopEmbeddedApplication = function (appInstance, name) {
    var applicationArray = this._applicationStorage[name];
    if (!applicationArray) {
        return false;
    }
    for (var n = 0, l = applicationArray.length; n < l; n++) {
        if (applicationArray[n] === appInstance) {
            applicationArray.splice(n, 1); //remove from storage
            appInstance.die && appInstance.die(); //call to die method
        }
    }
    this.trigger("appStopped:"+name,{name:name});

};

/**
 * Start appServer
 * @param {Node} container dome node of container
 * @return {Object} appServer new appServer instance
 */
AppServer.prototype.startAppServer = function (container) {
    this._appServersChildrenStorage.push(new AppServer(this, this._getDOMNode(container)));
    return this._appServersChildrenStorage[this._appServersChildrenStorage.length - 1];
};

/**
 * Get SharedModel
 * @param {String} modelName  folder on server in CommonModel folder
 * @param {Object} [isLimitToDomain] limit model to domain. If false use model as root AppServer Model
 * @returns {Promise}
 */

AppServer.prototype.getSharedModel = function (modelName, isLimitToDomain) {
    var that = this;
    return new Promise(function (resolve, reject) {
        that._getModule("model", modelName).then(function (someConstructor) {//getting model
            if (isLimitToDomain || !that.parentAppServer) {
                if (!that.sharedModelsStorage) {
                    that.sharedModelsStorage = new SharedModelsStorage(that);//init Model Storage
                }
                resolve(that.sharedModelsStorage.getInstance(someConstructor));
            } else {
                resolve(that.getRootAppServer().sharedModelsStorage.getInstance(someConstructor));
            }
        },reject);
    });
};

/**
 * Get library
 * @param {String} libraryName   file name in vendor folder
 * @returns {Promise}
 */

AppServer.prototype.getLibrary = function (libraryName) {
    libraryName = (libraryName.lastIndexOf(".js") + 3 === libraryName.length) ? libraryName : (libraryName + ".js");
    return this._getModule("vendor", libraryName);
};


/**
 * Get node based on text
 * @param {String|Object} node DOM node on id of the node
 * @private
 */
AppServer.prototype._getDOMNode = function (node) {
    return (node instanceof HTMLElement) ? node : document.getElementById(node);
};
/**
 * Register style. If this is a first style the append to head
 * @param {String} styleString string with md5 hash of style at start
 */
AppServer.prototype.registerStyle =function(styleString){
    if (styleString.indexOf('/')!==32){
        console.error("some wrong with style. should be md5 at start");
        return;
    }
    var md5 = styleString.slice(0, 32);
    if (this._registeredStyles.indexOf(md5) !==-1){
        //console.error("style is already register! Skipping registration");
        //console.groupCollapsed("Expand to show style");
        //console.log(styleString);
        //console.groupEnd("Expand to show style");

        return;
    }
    this._registeredStyles.push(md5);
    styleString = styleString.slice(33);
    var head = document.getElementsByTagName('head')[0],
        style = document.createElement('style');
    style.type = 'text/css';

    if (style.styleSheet){
        style.styleSheet.cssText = styleString;
    } else {
        style.appendChild(document.createTextNode(styleString));
    }
    head.appendChild(style);
};

/**
 * Get config
 */
AppServer.prototype.getConfig = function () {
    if (this.parentAppServer) {
        return this.parentAppServer.getConfig();
    }
    return urls;
};

/**
 * Get config by provider name
 * @param {String} providerName name of the provider
 */
AppServer.prototype.getProviderConfig = function (providerName) {
    if (this.parentAppServer) {
        return this.parentAppServer.getProviderConfig(providerName);
    }
    if (urls.providersConfig.hasOwnProperty(providerName)){
        return urls.providersConfig[providerName];
    }
    //console.error("Can't find providers config for "+providerName);
    return null;
};

/**
 * Getter for LMS
 */
AppServer.prototype.getLMSConfig = function (strConfigName) {
    //TODO remove this function. AppServer it's a framework, but LMS it's just a app!
     if (this.parentAppServer) {
        return this.parentAppServer.getLMSConfig();
     }
     return (typeof urls.lms[strConfigName] == 'undefined') ? null : urls.lms[strConfigName];
};
/**
 * Get root app server
 */
AppServer.prototype.getRootAppServer = function () {
    if (this.parentAppServer) {
        return this.parentAppServer.getRootAppServer();
    }
    return this;
};


/**
 * Init given inited module inside given app server. Or return inited model
 * @param {Object} module module name
 * @param {Object} appServer appServer insance
 */
AppServer.prototype.createInGivenAppServer = function(module,appServer){
    if (!appServer.sharedModelsStorage) {
        appServer.sharedModelsStorage = new SharedModelsStorage(appServer);//init Model Storage
    }
    return appServer.sharedModelsStorage.getInstance(module.constructor);
};

/**
 * App server events method add event
 * @param {String}  e "type:name:event"
 * @param {Function} fn callback function
 * @returns {*}
 */
AppServer.prototype.on = function (e, fn) {
    return this._events.on(e, fn);
};
/**
 * App server events method remove event
 * @param {String}  e "type:name:event"
 * @param {Function} fn callback function
 * @returns {*}
 */
AppServer.prototype.off = function (e, fn) {
    return this._events.off(e, fn);
};
/**
 * Return number of registered callbacks for given event
 * @param {String} e string "type:name:event"
 * @returns {Number} current events
 */
AppServer.prototype.getEventHandlersCount = function (e) {
    return this._events.count(e);
};
/**
 * App server events method Trigger  event
 * @param {String} e string "type:name:event"
 * @param {Object} [data] transferred data
 * @param {String} [direction] direction to propagation event. Possible variants is - "fromRoot", "toRoot", "all". If direction is not provided event fill be fire only on this appServer and will not be propagation
 * @param {boolean} [isSkipCurrentAppServer=false] skip current app server events trigger
 */
AppServer.prototype.trigger = function (e, data, direction,isSkipCurrentAppServer) {
    if (!isSkipCurrentAppServer){
    this._events.trigger(e, data);
    }

    if (direction) {
        this._propagateEvent(e, data, direction, this);
    }
};

/**
 * This function send event to the given direction.
 * @param {String} e string "type:name:event"
 * @param {Object} data transferred data
 * @param {String} direction direction to propagation event. Possible variants is - "fromRoot", "toRoot", "all". If direction is not provided event fill be fire only on this appServer and will not be propagation
 * @param {Object} from object which trigger event
 * @private
 */
AppServer.prototype._propagateEvent = function (e, data, direction, from) {
    if (from !== this) {
        this._events.trigger(e, data);
    }
    var that = this;
    if ((direction === "toRoot" || direction === "all") && this.parentAppServer && (this.parentAppServer !== from)) {
        this.parentAppServer._propagateEvent(e, data, direction, this);
    }
    if ((direction === "fromRoot" || direction === "all")) {
        this._appServersChildrenStorage.forEach(function (val) {
            if (val !== from) {
                val._propagateEvent(e, data, direction, that);
            }
        });
    }
};


module.exports = AppServer;
